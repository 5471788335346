/* eslint-disable sonarjs/no-identical-functions */
import { Injectable, inject } from '@angular/core';
import { Company } from '@models/company/company';
import type { ClientInitiative } from '@models/event/client-initiative';
import { EventManager } from '@models/event/event-manager';
import type { OpsEvent } from '@models/event/ops-event';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { SseService } from '@shared/services/sse.service';
import {
  SetCompanies,
  SetEvent,
  SetEventClientInitiative,
  SetEventManagers,
  UpdateEvent,
} from '@stores/event/event.actions';
import { filter, map, switchMap, take } from 'rxjs/operators';

export interface EventStateModel {
  event?: OpsEvent;
  companies?: Company[];
  eventManagers?: EventManager[];
  initiativeTitle?: string;
  initiativeDescription?: string;
  delayCancellable?: number;
}
export const EventStateToken = new StateToken<EventStateModel>('event');

@State({
  name: EventStateToken,
  defaults: null,
})
@Injectable()
export class EventState implements NgxsOnInit {
  private readonly sseService = inject(SseService);
  private readonly store = inject(Store);

  @Selector([EventStateToken])
  static event(state?: EventStateModel): OpsEvent | undefined {
    return state?.event;
  }

  @Selector([EventStateToken])
  static initiative(state?: EventStateModel): ClientInitiative {
    return { initiativeTitle: state?.initiativeTitle, initiativeDescription: state?.initiativeDescription };
  }

  @Selector([EventStateToken])
  static nbMaxPostulation(state?: EventStateModel): number | undefined {
    return state?.event?.nbMaxPostulation;
  }

  @Selector([EventStateToken])
  static date(state?: EventStateModel): Date | undefined {
    return state?.event?.date;
  }

  @Selector([EventStateToken])
  static companies(state?: EventStateModel): Company[] | undefined {
    return state?.companies;
  }

  @Selector([EventStateToken])
  static eventManagers(state?: EventStateModel): EventManager[] | undefined {
    return state?.eventManagers;
  }

  @Selector([EventStateToken])
  static delayCancellable(state?: EventStateModel): number | undefined {
    return state?.delayCancellable;
  }

  @Action(SetEvent)
  setEvent(ctx: StateContext<EventStateModel>, { event }: SetEvent): void {
    ctx.patchState({ event });
    const delayCancellable =
      (event.cvBookParameters.interviewDuration + event.cvBookParameters.interviewDebriefingDuration) / 2;
    ctx.patchState({ delayCancellable });
  }

  @Action(UpdateEvent)
  updateEvent(ctx: StateContext<EventStateModel>, { event }: UpdateEvent): void {
    ctx.setState(patch({ event: patch(event) }));

    if (event.cvBookParameters.interviewDuration) {
      const delayCancellable =
        (event.cvBookParameters.interviewDuration + event.cvBookParameters.interviewDebriefingDuration) / 2;
      ctx.patchState({ delayCancellable });
    }
  }

  @Action(SetCompanies)
  setCompanies(ctx: StateContext<EventStateModel>, { companies }: SetCompanies): void {
    ctx.patchState({ companies });
  }

  @Action(SetEventManagers)
  setEventManagers(ctx: StateContext<EventStateModel>, { eventManagers }: SetEventManagers): void {
    ctx.patchState({ eventManagers });
  }

  @Action(SetEventClientInitiative)
  setInitiative(ctx: StateContext<EventStateModel>, { initiative }: SetEventClientInitiative): void {
    ctx.patchState({
      initiativeTitle: initiative.initiativeTitle,
      initiativeDescription: initiative.initiativeDescription,
    });
  }

  ngxsOnInit(ctx?: StateContext<EventStateModel>): void {
    this.store
      .select(EventState.event)
      .pipe(
        filter((event) => !!event),
        take(1),
        switchMap((event) => this.sseService.getServerSentEvent<string>(`http://sourcii/event/${event?.id}`)),
        map((jsonString: string) => JSON.parse(jsonString) as OpsEvent)
      )
      .subscribe({
        next: (event: any) => {
          ctx?.dispatch(new UpdateEvent(event));
        },
      });
  }
}
