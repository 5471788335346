import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { SetTokenExpired } from '@stores/auth/auth.actions';
import { SetEvent } from '@stores/event/event.actions';
import { AuthService } from '@webservices/auth/auth.service';
import { OpsEventWebservice } from '@webservices/ops-event-api/ops-event.webservice';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class OpsEventInterceptor implements HttpInterceptor {
  private readonly authService = inject(AuthService);
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly opsEventWebservice = inject(OpsEventWebservice);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenFromStore = this.authService.getTokenFromStore();
    const apiReq = req.clone({
      url: req.url.replace(':TOKEN:', tokenFromStore ?? this.authService.getTokenFromCookies()),
    });
    return next.handle(apiReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 410) {
          return this.store.dispatch(new SetTokenExpired(true)).pipe(
            switchMap(() => this.opsEventWebservice.getEventInfoAfterExit()),
            switchMap((event) => this.store.dispatch(new SetEvent(event))),
            tap(() => {
              this.router.navigate(['/', FeaturesRoutingEnum.EventClosed]);
            }),
            switchMap(() => throwError(() => error))
          );
        }
        return throwError(() => error);
      })
    );
  }
}
