import { convertDate } from '@commons/utils/date/date';

export interface Participation {
  candidatedJobsIds: string[];
  eventId: string;
  jobsIds: string[];
  endAttendance: Date;
  firstName: string;
  lastName: string;
  mail: string;
  phone: string;
}

export function toParticipation(data: any = {}): Participation {
  return {
    ...data,
    endAttendance: convertDate(data.endAttendance),
  };
}
