import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchNotFoundResolver } from '@commons/utils/catch-error';
import { Company } from '@models/company/company';
import { Store } from '@ngxs/store';
import { SetCompanies } from '@stores/event/event.actions';
import { OpsEventWebservice } from '@webservices/ops-event-api/ops-event.webservice';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompaniesJobsResolver {
  private readonly opsEventWebservice = inject(OpsEventWebservice);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly location = inject(Location);

  resolve(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Company[]> {
    return this.opsEventWebservice.getCompaniesJobs().pipe(
      catchNotFoundResolver(this.router, this.location, state.url),
      tap((res: Company[]) => this.store.dispatch([new SetCompanies(res)]))
    );
  }
}
