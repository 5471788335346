<nav *ngIf="navItems$ | async as navItems" class="nav">
  <ul class="ul-clean nav__list">
    <li *ngFor="let navItem of navItems; trackBy: trackByItem" class="nav__item">
      <a
        *ngIf="!!navItem.route"
        class="nav__item__btn"
        [routerLink]="navItem.route"
        [attr.aria-label]="navItem.aria"
        routerLinkActive="nav__item--active"
      >
        <mat-icon [svgIcon]="navItem.icon" role="presentation"></mat-icon>
      </a>
      <button
        *ngIf="!navItem.route && navItem.onClick"
        class="btn-clean nav__item__btn"
        [attr.aria-label]="navItem.aria"
        (click)="navItem.onClick()"
        type="button"
      >
        <mat-icon class="nav__item__icon" [svgIcon]="navItem.icon" role="presentation"></mat-icon>
      </button>
      <p class="nav__name">{{ navItem.name }}</p>
    </li>
  </ul>
</nav>

<app-bottom-sheet (quitFn)="clearSubject()" ariaLabelSheet="Quitter l'événement">
  <ng-container *ngIf="(showConfirm$ | async) === false; else confirmQuitTpl">
    <p class="bottom-sheet__title">Vous souhaitez quitter l&apos;événement&nbsp;?</p>
    <p class="bottom-sheet__text">
      <strong>Attention</strong>, cette action est définitive&nbsp;! Si vous avez des entretiens programmés, les
      entreprises seront informées de votre annulation.
    </p>
    <button class="btn--caution bottom-sheet__button" (click)="showConfirm$.next(true)" type="button">
      Quitter l&apos;événement
    </button>
  </ng-container>

  <ng-template #confirmQuitTpl>
    <p class="bottom-sheet__confirm-title">Vous souhaitez quitter l&apos;événement&nbsp;?</p>

    <ul class="ul-clean bottom-sheet__actions">
      <li>
        <button class="btn--classic bottom-sheet__confirm-button" (click)="closeModal()">Non</button>
      </li>
      <li>
        <button class="btn--classic bottom-sheet__confirm-button" (click)="confirmQuit()">Oui</button>
      </li>
    </ul>
  </ng-template>
</app-bottom-sheet>
