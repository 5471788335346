import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetSseToken, SetToken, SetTokenExpired } from '@stores/auth/auth.actions';

export interface AuthStateModel {
  token?: string;
  sseToken?: string;
  tokenExpired?: boolean;
}
export const AuthStateToken = new StateToken<AuthStateModel>('auth');

@State({
  name: AuthStateToken,
  defaults: null,
})
@Injectable()
export class AuthState {
  @Selector([AuthStateToken])
  static isAuth(state?: AuthStateModel): boolean {
    return !!state?.token;
  }

  @Selector([AuthStateToken])
  static token(state?: AuthStateModel): string | undefined {
    return state?.token;
  }

  @Selector([AuthStateToken])
  static sseToken(state?: AuthStateModel): string | undefined {
    return state?.sseToken;
  }

  @Selector([AuthStateToken])
  static hasTokenExpired(state?: AuthStateModel): boolean | undefined {
    return state?.tokenExpired;
  }

  @Action(SetToken)
  setToken(ctx: StateContext<AuthStateModel>, { token }: SetToken): void {
    ctx.patchState({ token, tokenExpired: false });
  }

  @Action(SetSseToken)
  setSseToken(ctx: StateContext<AuthStateModel>, { token }: SetSseToken): void {
    ctx.patchState({ sseToken: token });
  }

  @Action(SetTokenExpired)
  setTokenExpired(ctx: StateContext<AuthStateModel>, { expired }: SetTokenExpired): void {
    ctx.patchState({ tokenExpired: expired });
  }
}
