/* eslint-disable sonarjs/no-duplicate-string */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@commons/guards/auth-guard';
import { QuicklinkSharedModule } from '@core/ngx-quicklink.shared.module';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { SharedModule } from '@shared/shared.module';
import { CompaniesJobsResolver } from '@webservices/ops-event-api/company-jobs.resolver';
import { OpsEventPlanningResolver } from '@webservices/ops-event-api/ops-event-planning.resolver';
import { OpsEventInterceptor } from '@webservices/ops-event-api/ops-event.interceptor';
import { ContractWebservice, DegreeWebservice, JOB_API_CONFIG, SCHOOL_API_CONFIG } from '@wizbii/webservices';

const routes: Routes = [
  {
    path: 'sign-in/:token',
    canActivate: [AuthGuard],
    resolve: {
      planning: OpsEventPlanningResolver,
      companiesJobs: CompaniesJobsResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: `${FeaturesRoutingEnum.Jobs}`,
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      planning: OpsEventPlanningResolver,
      companiesJobs: CompaniesJobsResolver,
    },
    children: [
      {
        path: FeaturesRoutingEnum.Jobs,
        loadChildren: () => import('./jobs/jobs.module').then((module) => module.JobsModule),
      },
      {
        path: FeaturesRoutingEnum.Help,
        loadChildren: () => import('./help/help.module').then((module) => module.HelpModule),
      },
      {
        path: FeaturesRoutingEnum.Info,
        loadChildren: () => import('./info/info.module').then((module) => module.InfoModule),
      },
      {
        path: FeaturesRoutingEnum.Interview,
        loadChildren: () => import('./interview/interview.module').then((module) => module.InterviewModule),
      },
      {
        path: FeaturesRoutingEnum.Interviews,
        loadChildren: () => import('./interviews/interviews.module').then((module) => module.InterviewsModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: `${FeaturesRoutingEnum.Jobs}`,
      },
    ],
  },
  {
    path: FeaturesRoutingEnum.EventClosed,
    loadChildren: () => import('./event-closed/event-closed.module').then((module) => module.EventClosedModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), QuicklinkSharedModule, SharedModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OpsEventInterceptor,
      multi: true,
    },
    { provide: JOB_API_CONFIG, useValue: { baseUrl: environment.api.job } },
    ContractWebservice,
    { provide: SCHOOL_API_CONFIG, useValue: { baseUrl: environment.api.school } },
    DegreeWebservice,
  ],
})
export class FeaturesModule {}
