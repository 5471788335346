import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, NgModule, NgZone } from '@angular/core';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ClosedEventComponent } from '@commons/components/closed-event/closed-event.component';
import { HeaderComponent } from '@commons/components/header/header.component';
import { NavComponent } from '@commons/components/nav/nav.component';
import { AuthGuard } from '@commons/guards/auth-guard';
import { bugsnagConfig } from '@config/bugsnag.config';
import { ngxsConfig } from '@config/ngxs.config';
import { routerConfig } from '@config/router.config';
import { trackingConfig } from '@config/tracking-config';
import { CoreComponent } from '@core/components/core/core.component';
import { dbConfig } from '@core/indexed-db.interface';
import { OfflineInterceptor } from '@core/interceptors/offline.interceptor';
import { QuicklinkSharedModule } from '@core/ngx-quicklink.shared.module';
import { WizbiiRouterStateSerializer } from '@core/serializer/router.serializer';
import { environment } from '@environment';
import { FeaturesModule } from '@features/features.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { AuthState } from '@stores/auth/auth.state';
import { CandidateState } from '@stores/candidate/candidate.state';
import { ContractsState } from '@stores/contracts/contracts.state';
import { DegreesState } from '@stores/degrees/degrees.state';
import { EventState } from '@stores/event/event.state';
import { SelectedInterviewState } from '@stores/selected-interview/selected-interview.state';
import { StuckState } from '@stores/stuck/stuck.state';
import { bugsnagErrorHandlerFactory } from '@wizbii/angular-bugsnag';
import { ImageService } from '@wizbii/angular-utilities';
import { LocaleEnum } from '@wizbii/models';
import { setupTracking, track } from '@wizbii/tracking';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { from } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

registerLocaleData(localeFr);

@NgModule({
  declarations: [CoreComponent],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HeaderComponent,
    FeaturesModule,
    ClosedEventComponent,
    NavComponent,
    QuicklinkSharedModule,
    RouterModule.forRoot([], routerConfig),
    NgxsModule.forRoot(
      [AuthState, EventState, CandidateState, ContractsState, DegreesState, SelectedInterviewState, StuckState],
      ngxsConfig
    ),
    NgxsRouterPluginModule.forRoot(),
    NgxIndexedDBModule.forRoot(dbConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable',
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'Candidate Web App', maxAge: 30, disabled: false }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineInterceptor,
      multi: true,
    },
    AuthGuard,
    { provide: LOCALE_ID, useValue: LocaleEnum.fr_FR },
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: ErrorHandler,
      useFactory: bugsnagErrorHandlerFactory(bugsnagConfig),
    },
    {
      provide: ImageService,
      useValue: new ImageService(environment.api.imaginary),
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', hideRequiredMarker: true },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
      useValue: { panelClass: 'wiz-bottom-sheet', backdropClass: 'wiz-backdrop', hasBackdrop: true },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { panelClass: 'wiz-dialog', backdropClass: 'wiz-backdrop', hasBackdrop: true },
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },
  ],
  bootstrap: [CoreComponent],
})
export class CoreModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, router: Router, ngZone: NgZone) {
    ngZone.runOutsideAngular(() => {
      matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl(`/assets/sprite.svg`));
      this.initializeTracking(router);
    });
  }

  private initializeTracking(router: Router) {
    setupTracking(trackingConfig).then(() => {
      let previousUrl: URL | null;

      from(window.WizbiiGdpr.getConsents())
        .pipe(
          switchMap(() => router.events),
          filter((event) => event instanceof NavigationEnd),
          map((event) => (event as NavigationEnd).urlAfterRedirects),
          startWith(window.location.href.replace(window.origin, '')),
          map((page) => ({ url: window.location.href, page }))
        )
        .subscribe({
          next: (event: any) => {
            const url = new URL(event.url);

            // send pageview only for pages without queryParams
            if (previousUrl?.hash !== url.hash || previousUrl.pathname !== url.pathname) {
              track('pageview', event);
              previousUrl = url;
            }
          },
        });
    });
  }
}
