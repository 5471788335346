import { ChangeDetectionStrategy, Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { Router, RouterModule } from '@angular/router';
import { BottomSheetComponent } from '@commons/components/bottom-sheet/bottom-sheet.component';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import type { ClientInitiative } from '@models/event/client-initiative';
import { EventManager } from '@models/event/event-manager';
import { OpsEvent } from '@models/event/ops-event';
import { Select, Store } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { SetTokenExpired } from '@stores/auth/auth.actions';
import { EventState } from '@stores/event/event.state';
import { OpsEventWebservice } from '@webservices/ops-event-api/ops-event.webservice';
import equal from 'fast-deep-equal/es6';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

interface NavItem {
  icon: string;
  name: string;
  aria: string;
  route?: string[];
  onClick?(): void;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, RouterModule, BottomSheetComponent],
})
export class NavComponent implements OnInit {
  @Select(EventState.eventManagers)
  eventManagers$!: Observable<EventManager[]>;

  @Select(EventState.initiative)
  initiative$!: Observable<ClientInitiative | null>;

  @Select(EventState.event)
  event$!: Observable<OpsEvent>;

  hasEventManager$?: Observable<boolean>;

  @ViewChild(BottomSheetComponent) bottomSheetTemplate?: BottomSheetComponent;

  showConfirm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  navItems$!: Observable<NavItem[] | undefined>;

  private readonly navItemsWithoutHelp: NavItem[] = [
    {
      icon: 'briefcase',
      name: 'Entreprises',
      route: ['/', FeaturesRoutingEnum.Jobs],
      aria: 'Liste des emplois par entreprise',
    },
    {
      icon: 'people',
      name: 'Mes entretiens',
      route: ['/', FeaturesRoutingEnum.Interviews],
      aria: 'Consulter mes entretiens',
    },
  ];

  private readonly navItemsWithHelp: NavItem[] = [
    {
      icon: 'briefcase',
      name: 'Entreprises',
      route: ['/', FeaturesRoutingEnum.Jobs],
      aria: 'Liste des emplois par entreprise',
    },
    {
      icon: 'people',
      name: 'Mes entretiens',
      route: ['/', FeaturesRoutingEnum.Interviews],
      aria: 'Consulter mes entretiens',
    },
    {
      icon: 'question',
      name: 'Aide',
      route: ['/', FeaturesRoutingEnum.Help],
      aria: 'Aide',
    },
  ];

  private readonly infoItem = {
    icon: 'info',
    name: 'Info',
    aria: 'Info',
    route: ['/', FeaturesRoutingEnum.Info],
  };

  private readonly router = inject(Router);
  private readonly opsEventWebservice = inject(OpsEventWebservice);
  private readonly store = inject(Store);

  ngOnInit(): void {
    this.hasEventManager$ = this.eventManagers$.pipe(
      map((eventManagers) => eventManagers && eventManagers.length > 0 && !!eventManagers[0].phoneNumber),
      distinctUntilChanged()
    );
    this.navItems$ = combineLatest([this.hasEventManager$, this.initiative$, this.event$]).pipe(
      map(([hasEventManager, initiative, event]) => {
        if (
          initiative?.initiativeTitle &&
          initiative.initiativeDescription &&
          event.type === 'job_dating' &&
          (event.clientId === 'youzful' || event.clientId === 'credit-agricole')
        ) {
          return hasEventManager
            ? [...this.navItemsWithHelp, this.infoItem]
            : [...this.navItemsWithoutHelp, this.infoItem];
        } else {
          return hasEventManager ? this.navItemsWithHelp : this.navItemsWithoutHelp;
        }
      }),
      distinctUntilChanged((prev, curr) => equal(prev, curr))
    );
  }

  quitEvent(config?: MatBottomSheetConfig): void {
    this.bottomSheetTemplate?.open(config);
  }

  closeModal(): void {
    this.bottomSheetTemplate?.close();
    this.clearSubject();
  }

  clearSubject(): void {
    setTimeout(() => this.showConfirm$.next(false), 500);
  }

  confirmQuit(): void {
    this.opsEventWebservice.exitEvent().subscribe(() => {
      this.store.dispatch(new SetTokenExpired(true));
      this.bottomSheetTemplate?.close();
      this.router.navigate(['/', FeaturesRoutingEnum.EventClosed]);
    });
  }

  trackByItem(_: number, item: NavItem): string {
    return item.icon;
  }
}
