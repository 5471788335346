import { CandidateInformation, toCandidateInformation } from '@models/candidate/candidate-information';
import { EventManager } from '@models/event/event-manager';
import { OpsEvent, toOpsEvent } from '@models/event/ops-event';

export interface EventGlobalInfo {
  event: OpsEvent;
  candidate: CandidateInformation;
  eventManagers: EventManager[];
  initiativeDescription: string;
  initiativeTitle: string;
}

export function toEventGlobalInfo(data: any = {}): EventGlobalInfo {
  return {
    ...data,
    event: toOpsEvent(data.event),
    candidate: toCandidateInformation(data.candidate),
  };
}
