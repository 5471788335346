import { Company } from '@models/company/company';
import type { ClientInitiative } from '@models/event/client-initiative';
import { EventManager } from '@models/event/event-manager';
import { OpsEvent } from '@models/event/ops-event';

export class SetEvent {
  static readonly type = '[Event] Set Event';
  constructor(public event: OpsEvent) {}
}

export class UpdateEvent {
  static readonly type = '[Event] Update Event';
  constructor(public event: OpsEvent) {}
}

export class SetCompanies {
  static readonly type = '[Event] Set Companies';
  constructor(public companies: Company[]) {}
}

export class SetEventManagers {
  static readonly type = '[Event] Set Event Managers';
  constructor(public eventManagers: EventManager[]) {}
}

export class SetEventClientInitiative {
  static readonly type = '[Event] Set Event Client Initiative';
  constructor(public initiative: ClientInitiative) {}
}
