import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Select, Store } from '@ngxs/store';
import { SetToken } from '@stores/auth/auth.actions';
import { AuthState } from '@stores/auth/auth.state';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  @Select(AuthState.isAuth)
  isAuth$!: Observable<boolean>;

  @Select(AuthState.token)
  token$!: Observable<string>;

  private readonly store = inject(Store);
  private readonly cookieService = inject(CookieService);

  getTokenFromStore(): string | undefined {
    return this.store.selectSnapshot(AuthState.token);
  }

  setTokenInStore(token: string): void {
    this.isAuth$.pipe(take(1)).subscribe((isAuth) => {
      if (!isAuth) {
        this.store.dispatch(new SetToken(token));
      }
    });
  }

  getTokenFromCookies(): string {
    return this.cookieService.get('token');
  }

  setTokenCookie(token: string): void {
    const cookieDomain = this.getCookieDomain();
    const msIn390Days = 1000 * 3600 * 24 * 390;
    const expiry = new Date(Date.now() + msIn390Days);

    this.cookieService.set(
      'token',
      token,
      expiry,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : `.${environment.apiDomain}`;
  }
}
