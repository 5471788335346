import { Injectable, inject } from '@angular/core';
import { CandidateInformation } from '@models/candidate/candidate-information';
import { Participation } from '@models/candidate/participation';
import type { CandidatePlanning } from '@models/planning/candidate-planning';
import { PlanningInterview } from '@models/planning/planning-interview';
import { PlanningInterviewStatusEnum } from '@models/planning/planning-interview-status.enum';
import { RemainingInterview } from '@models/planning/remaining-interview';
import { Action, NgxsOnInit, Select, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { SseService } from '@shared/services/sse.service';
import { AuthState } from '@stores/auth/auth.state';
import {
  SetCandidate,
  SetCandidateJobsIds,
  SetCandidatePlanning,
  UpdateCandidate,
  UpdateParticipation,
} from '@stores/candidate/candidate.actions';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

export interface CandidateStateModel {
  candidate: CandidateInformation;
  planning: CandidatePlanning;
}
export const CandidateStateToken = new StateToken<CandidateStateModel>('candidate');

@State({
  name: CandidateStateToken,
  defaults: null,
})
@Injectable()
export class CandidateState implements NgxsOnInit {
  @Select(AuthState.token)
  candidateToken$!: Observable<string>;

  readonly #sseService = inject(SseService);

  @Selector([CandidateStateToken])
  static candidate(state?: CandidateStateModel): CandidateInformation | undefined {
    return state?.candidate;
  }

  @Selector([CandidateStateToken])
  static participations(state?: CandidateStateModel): Participation[] | undefined {
    return state?.candidate.participations;
  }

  @Selector([CandidateStateToken])
  static planning(state?: CandidateStateModel): CandidatePlanning | undefined {
    return state?.planning;
  }

  @Selector([CandidateStateToken])
  static remainingIntendedInterviews(state?: CandidateStateModel): RemainingInterview[] | undefined {
    return state?.planning.remainingIntendedInterviews;
  }

  @Selector([CandidateState.planning])
  static hasPlanning(planning: CandidatePlanning): boolean {
    return !!planning;
  }

  @Selector([CandidateState.planning])
  static hasInterviews(planning: CandidatePlanning): boolean {
    return !!planning && planning.interviews.length > 0;
  }

  @Selector([CandidateState.planning])
  static nextInterview(planning: CandidatePlanning): PlanningInterview | undefined {
    const interviews = planning.interviews;
    if (!interviews || interviews.length === 0) {
      return undefined;
    }

    const interviewsSortedByDate = interviews.slice().sort((interviewA, interviewB) => {
      return new Date(interviewA.dateRange.start).getTime() - new Date(interviewB.dateRange.start).getTime();
    });

    const nexInterviews = interviewsSortedByDate.filter(
      (interview) => interview.status === PlanningInterviewStatusEnum.PLANNED
    );

    return nexInterviews[0];
  }

  @Selector([CandidateState.nextInterview])
  static hasNextInterview(interview: PlanningInterview | undefined): boolean {
    return !!interview;
  }

  ngxsOnInit(ctx?: StateContext<CandidateStateModel>): void {
    this.candidateToken$
      .pipe(
        filter((token) => !!token),
        take(1),
        switchMap((token) => this.#sseService.getServerSentEvent<string>(`http://sourcii/candidate/${token}`)),
        map(
          (jsonString: string) =>
            JSON.parse(jsonString) as { planning: CandidatePlanning; participation: Participation }
        )
      )
      .subscribe({
        next: ({ planning, participation }: { planning: CandidatePlanning; participation: Participation }) => {
          ctx?.dispatch([new SetCandidatePlanning(planning), new UpdateParticipation(participation)]);
        },
      });
  }

  @Action(SetCandidate)
  setCandidate(ctx: StateContext<CandidateStateModel>, { candidate }: SetCandidate): void {
    ctx.patchState({ candidate });
  }

  @Action(UpdateCandidate)
  updateCandidate(ctx: StateContext<CandidateStateModel>, { candidate }: UpdateCandidate): void {
    ctx.setState(patch({ candidate: patch(candidate) }));
  }

  @Action(UpdateParticipation)
  updateParticipation(ctx: StateContext<CandidateStateModel>, { participation }: UpdateParticipation): void {
    ctx.setState(
      patch({
        candidate: patch({
          participations: updateItem<Participation>((p) => p.eventId === participation.eventId, participation),
        }),
      })
    );
  }

  @Action(SetCandidateJobsIds)
  setCandidateJobsIds(ctx: StateContext<CandidateStateModel>, { jobsIds, eventId }: SetCandidateJobsIds): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const participation: Participation = ctx
      .getState()
      .candidate.participations.find((participation) => participation.eventId === eventId)!;

    ctx.setState(
      patch<CandidateStateModel>({
        candidate: patch<CandidateInformation>({
          participations: updateItem<Participation>((participation) => participation.eventId === eventId, {
            ...participation,
            jobsIds,
          }),
        }),
      })
    );
  }

  @Action(SetCandidatePlanning)
  setCandidatePlanning(ctx: StateContext<CandidateStateModel>, { candidatePlanning }: SetCandidatePlanning): void {
    ctx.patchState({
      planning: candidatePlanning,
    });
  }
}
