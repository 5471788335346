import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { CandidateInformation, toCandidateInformation } from '@models/candidate/candidate-information';
import { Company } from '@models/company/company';
import { EventGlobalInfo, toEventGlobalInfo } from '@models/event/event-global-info';
import { OpsEvent, toOpsEvent } from '@models/event/ops-event';
import { CandidatePlanning } from '@models/planning/candidate-planning';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OpsEventWebservice {
  baseUrlV2 = `${environment.api.opsEvent}/v2/candidate-app/:TOKEN:`;

  private readonly http = inject(HttpClient);

  getConfigFromToken(): Observable<EventGlobalInfo> {
    return this.http.get<EventGlobalInfo>(`${this.baseUrlV2}`).pipe(map(toEventGlobalInfo));
  }

  getCompaniesJobs(): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.baseUrlV2}/companies`);
  }

  applyJob(jobId: string): Observable<CandidateInformation> {
    return this.http
      .post<CandidateInformation>(`${this.baseUrlV2}/applications/${jobId}`, {})
      .pipe(map(toCandidateInformation));
  }

  applyJobs(jobIds: string[]): Observable<CandidateInformation> {
    return this.http
      .post<CandidateInformation>(`${this.baseUrlV2}/applications`, {
        jobIds,
      })
      .pipe(map(toCandidateInformation));
  }

  cancelApplication(jobId: string | string[]): Observable<CandidateInformation> {
    return this.http
      .delete<CandidateInformation>(`${this.baseUrlV2}/applications`, {
        body: {
          jobIds: Array.isArray(jobId) ? jobId : [jobId],
        },
      })
      .pipe(map(toCandidateInformation));
  }

  getCandidatePlaning(): Observable<CandidatePlanning> {
    return this.http.get<CandidatePlanning>(`${this.baseUrlV2}/planning`);
  }

  getEventDescription(eventId: string): Observable<string> {
    return this.http
      .get<{ description: string }>(`${environment.api.opsEvent}/api/event/${eventId}/preview-description`)
      .pipe(map((payload) => payload.description));
  }

  exitEvent(): Observable<any> {
    return this.http.post(`${this.baseUrlV2}/exit`, {});
  }

  getEventInfoAfterExit(): Observable<OpsEvent> {
    return this.http.get<OpsEvent>(`${this.baseUrlV2}/event`).pipe(map(toOpsEvent));
  }
}
