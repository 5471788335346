import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environment';
import { Select, Store } from '@ngxs/store';
import { SetSseToken } from '@stores/auth/auth.actions';
import { AuthState } from '@stores/auth/auth.state';
import { MercureCredentialsService } from '@webservices/ops-event-api/mercure-credentials.service';
import { getMessagesStream } from '@wizbii/angular-utilities';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import * as _EventSourcePolyfill from 'event-source-polyfill';
import { Observable, iif, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

const EventSourcePolyfill = _EventSourcePolyfill.EventSourcePolyfill;

@Injectable({
  providedIn: 'root',
})
export class SseService {
  @Select(AuthState.sseToken)
  sseToken$!: Observable<string>;

  private readonly jwtHelperService: JwtHelperService;

  constructor(private readonly store: Store, private readonly mercureCredentialsService: MercureCredentialsService) {
    this.jwtHelperService = new JwtHelperService();
  }

  getServerSentEvent<T>(topic: string): Observable<T> {
    return this.getToken().pipe(
      take(1),
      switchMap((token) =>
        getMessagesStream<T>(this.getEventSource(`${environment.api.mercure}?ngsw-bypass=true&topic=${topic}`, token))
      )
    );
  }

  private getToken(): Observable<string> {
    return this.sseToken$.pipe(
      switchMap((token) =>
        iif(
          () => !token || this.jwtHelperService.isTokenExpired(token),
          this.mercureCredentialsService.getToken().pipe(
            map((newToken) => newToken.token),
            tap((newToken) => this.store.dispatch(new SetSseToken(newToken)))
          ),
          of(token)
        )
      )
    );
  }

  private getEventSource(url: string, token?: string): typeof EventSourcePolyfill {
    return new EventSourcePolyfill(url, {
      headers: { ...(token ? { Authorization: `Bearer ${token}` } : {}) },
    });
  }
}
