import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CandidatePlanning } from '@models/planning/candidate-planning';
import { Store } from '@ngxs/store';
import { SetCandidatePlanning } from '@stores/candidate/candidate.actions';
import { CandidateState } from '@stores/candidate/candidate.state';
import { OpsEventWebservice } from '@webservices/ops-event-api/ops-event.webservice';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OpsEventPlanningResolver {
  private readonly opsEventWebservice = inject(OpsEventWebservice);
  private readonly store = inject(Store);

  resolve(_: ActivatedRouteSnapshot): Observable<CandidatePlanning | undefined> {
    const planning = this.store.selectSnapshot(CandidateState.planning);

    return (planning ? of(planning) : this.opsEventWebservice.getCandidatePlaning()).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 428) {
          return of(undefined);
        }
        return throwError(() => err);
      }),
      tap((candidatePlannig) => this.store.dispatch(new SetCandidatePlanning(candidatePlannig)))
    );
  }
}
