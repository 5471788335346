<header class="header" [class]="'header--' + size" [class.header--has-planning]="(hasNextInterview$ | async) === true">
  <div *ngIf="event$ | async as event" class="header__event-infos">
    <p class="header__event-infos__name">{{ event.publicName }}</p>
    <p class="header__event-infos__date">{{ event.date | date : 'shortDate' }}</p>
  </div>

  <ng-container *ngIf="navigateBack; else simpleTitle">
    <div class="header__title header__title__nav-back">
      <a class="btn-clean header__title__nav-back__icon" [routerLink]="navigateBackRoute">
        <mat-icon svgIcon="arrow-left"></mat-icon>
      </a>
      <span class="header__title__nav-back__text">
        <h1 class="header__title__nav-back__text__content">{{ title }}</h1>
      </span>
    </div>
  </ng-container>
</header>

<ng-template #simpleTitle>
  <h1 class="header__title header__title__simple">{{ title }}</h1>
</ng-template>
