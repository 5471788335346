import { DBConfig } from 'ngx-indexed-db';

export const indexedDBName = 'Candidate App Offline requests';
export const indexedDBStore = 'requests';

export const dbConfig: DBConfig = {
  name: indexedDBName,
  version: 3,
  objectStoresMeta: [
    {
      store: indexedDBStore,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'url', keypath: 'url', options: { unique: false } },
        { name: 'body', keypath: 'body', options: { unique: false } },
        { name: 'headers', keypath: 'headers', options: { unique: false } },
        { name: 'context', keypath: 'context', options: { unique: false } },
        { name: 'reportProgress', keypath: 'reportProgress', options: { unique: false } },
        { name: 'withCredentials', keypath: 'withCredentials', options: { unique: false } },
        { name: 'responseType', keypath: 'responseType', options: { unique: false } },
        { name: 'method', keypath: 'method', options: { unique: false } },
        { name: 'params', keypath: 'params', options: { unique: false } },
        { name: 'urlWithParams', keypath: 'urlWithParams', options: { unique: false } },
      ],
    },
  ],
};
