import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OpsEvent } from '@models/event/ops-event';
import { Select } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { CandidateState } from '@stores/candidate/candidate.state';
import { EventState } from '@stores/event/event.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, RouterModule],
})
export class HeaderComponent {
  @Select(EventState.event)
  event$!: Observable<OpsEvent>;

  @Select(CandidateState.hasNextInterview)
  hasNextInterview$?: Observable<boolean>;

  @Input() size!: string;

  @Input() title?: string;

  @Input() navigateBack!: boolean;

  @Input() navigateBackRoute?: string[];
}
