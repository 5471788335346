/* eslint-disable */
import { buildConfig } from './build-config';
import { getBuildEnvVar, getRuntimeEnvVar } from '@wizbii/angular-utilities';

const packageJsonVersionAtBuild = require('../../package.json').version; // eslint-disable-line

// eslint-disable-next-line @typescript-eslint/ban-tss-comment
// @ts-ignore
const production: boolean = buildConfig === 'production';
const apiDomain = getRuntimeEnvVar('WIZBII_DOMAIN');
const ciCommitSha = getBuildEnvVar('CI_COMMIT_SHA');
const analyticsUai = getRuntimeEnvVar('ANALYTICS_UAI');

export const environment = {
  applicationId: 'candidate-app',
  platform: getRuntimeEnvVar('PLATFORM'),
  apiDomain,
  isDeployed: ciCommitSha !== 'local',
  production,
  version: production ? packageJsonVersionAtBuild : getBuildEnvVar('GIT_BRANCH_NAME'),
  i18n: {
    lang: 'fr',
    locale: 'fr_FR',
  },
  bugsnag: {
    key: getRuntimeEnvVar('BUGSNAG_KEY'),
  },
  api: {
    googleStorage: 'https://storage.googleapis.com',
    imaginary: `https://imaginary.${apiDomain}`,
    mercure: `https://mercure-hub.${apiDomain}/.well-known/mercure`,
    job: `https://job-api.${apiDomain}`,
    opsCompany: `https://company-api.${apiDomain}`,
    opsEvent: `https://ops-event.api.${apiDomain}`,
    place: `https://place.${apiDomain}`,
    school: `https://school-api.${apiDomain}`,
  },
  analytics: {
    waTrackingId: analyticsUai,
    gaTrackingId: analyticsUai,
    hotjar: {
      id: '2858606',
      version: '6',
    },
  },
  wizbiiFiles: getRuntimeEnvVar('FILE_API_BUCKET'),
};

if (!production) {
  import('zone.js/dist/zone-error' as any); // Included with Angular CLI.
}
