import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetStuck } from '@stores/stuck/stuck.actions';

export type StuckStateModel = boolean;
export const StuckStateToken = new StateToken<StuckStateModel>('stuck');

@State({
  name: StuckStateToken,
  defaults: false,
})
@Injectable()
export class StuckState {
  @Selector([StuckStateToken])
  static isStuck(state?: StuckStateModel): boolean | undefined {
    return state;
  }
  @Action(SetStuck)
  setToken(ctx: StateContext<StuckStateModel>, { isStuck }: SetStuck): void {
    ctx.setState(isStuck);
  }
}
