import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BottomSheetComponent } from '@commons/components/bottom-sheet/bottom-sheet.component';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-closed-event',
  templateUrl: './closed-event.component.html',
  styleUrls: ['./closed-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, BottomSheetComponent],
})
export class ClosedEventComponent {
  @Input() eventName!: string;
  @Input() eventDate!: Date;
}
