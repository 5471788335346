import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpRequest } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event, NavigationEnd, Router } from '@angular/router';
import { indexedDBStore } from '@core/indexed-db.interface';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Participation } from '@models/candidate/participation';
import { EventGlobalInfo } from '@models/event/event-global-info';
import { OpsEvent } from '@models/event/ops-event';
import { HeaderInfo } from '@models/utils/header-info';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { NetworkService } from '@shared/services/network/network.service';
import { OnlineStatus } from '@shared/services/network/online-status.enum';
import { SetTokenExpired } from '@stores/auth/auth.actions';
import { AuthState } from '@stores/auth/auth.state';
import { SetCandidate } from '@stores/candidate/candidate.actions';
import { CandidateState } from '@stores/candidate/candidate.state';
import { SetEvent, SetEventClientInitiative, SetEventManagers } from '@stores/event/event.actions';
import { EventCandidateSelectors } from '@stores/selectors/event-candidate.selectors';
import { SetStuck } from '@stores/stuck/stuck.actions';
import { OpsEventWebservice } from '@webservices/ops-event-api/ops-event.webservice';
import { WebVitalsOptions, WebVitalsParams, sendWebVitals } from '@wizbii/utils';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject, EMPTY, Observable, concat, fromEvent, iif } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap, toArray } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent implements OnInit, AfterViewInit {
  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.accessibility-on') accessibilityEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled(): boolean {
    return !this.hoverEnabled;
  }

  @HostBinding('class.accessibility-off')
  get accessibilityDisabled(): boolean {
    return !this.accessibilityEnabled;
  }

  @Select(AuthState.hasTokenExpired)
  hasTokenExpired$!: Observable<boolean>;

  @Select(EventCandidateSelectors.eventParticipation)
  eventParticipation$!: Observable<Participation | undefined>;

  @Select(CandidateState.hasNextInterview)
  hasNextInterview$?: Observable<boolean>;

  headerClass = 'header';

  event?: OpsEvent;
  isEventOpen = true;
  header$!: Observable<HeaderInfo>;
  isShowNavbar$!: Observable<boolean>;
  hasFloatingButton$!: Observable<boolean>;

  isStuck$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  noStickyHeader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  stickyHeader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly router: Router,
    private readonly opsEventWebservice: OpsEventWebservice,
    private readonly store: Store,
    private readonly network: NetworkService,
    private readonly dbService: NgxIndexedDBService,
    private readonly http: HttpClient,
    private readonly title: Title
  ) {
    this.hasTokenExpired$
      .pipe(
        untilDestroyed(this),
        filter((value) => value !== undefined)
      )
      .subscribe((tokenExpired) => {
        if (tokenExpired) {
          this.isEventOpen = false;
          this.router.navigate(['/', FeaturesRoutingEnum.EventClosed]);
        } else {
          this.checkOfflineRequests();
          this.checkIfCandidateIsGone();
          this.triggerObserveHeaders(router);
        }
      });
  }

  private checkIfCandidateIsGone(): void {
    this.eventParticipation$
      .pipe(
        filter((participation): participation is Participation => !!participation?.endAttendance),
        map((participation) => new Date().getTime() < new Date(participation.endAttendance).getTime())
      )
      .subscribe({
        next: (isEventOpen) => {
          if (!isEventOpen) {
            this.store.dispatch(new SetTokenExpired(true));
            this.router.navigate(['/', FeaturesRoutingEnum.EventClosed]);
          }
        },
      });
  }

  private checkOfflineRequests(): void {
    this.network.status
      .pipe(
        switchMap((status) =>
          iif(
            () => status === OnlineStatus.ONLINE,
            this.dbService.getAll<HttpRequest<any>>(indexedDBStore).pipe(
              switchMap((requests: any[]) =>
                concat(
                  ...requests.map((r) =>
                    this.http
                      .request(
                        new HttpRequest(r.method, r.url, r.body, {
                          headers: r.headers.headers,
                          context: r.context,
                          reportProgress: r.reportProgress,
                          responseType: r.responseType,
                          withCredentials: r.withCredentials,
                        })
                      )
                      .pipe(catchError(() => EMPTY))
                  )
                ).pipe(toArray())
              ),
              switchMap(() => this.dbService.clear(indexedDBStore)),
              switchMap(() => this.opsEventWebservice.getConfigFromToken())
            ),
            this.opsEventWebservice.getConfigFromToken()
          )
        )
      )
      .subscribe((res: EventGlobalInfo) => {
        this.store.dispatch([
          new SetEvent(res.event),
          new SetCandidate(res.candidate),
          new SetEventManagers(res.eventManagers),
          new SetEventClientInitiative({
            initiativeTitle: res.initiativeTitle,
            initiativeDescription: res.initiativeDescription,
          }),
        ]);
        this.event = res.event;
        this.title.setTitle(this.event.publicName ?? this.event.name);
        this.checkIfEventIsOpen(this.event.date, new Date());
      });
  }

  ngOnInit(): void {
    if (document.documentElement.lang === '') {
      document.documentElement.lang = environment.i18n.lang;
    }

    /**
     * Disable hover on `touchstart` to cover browsers that do not support pointer events.
     * https://caniuse.com/#feat=pointer
     */
    fromEvent(window, 'touchstart', { passive: true }).subscribe({
      next: () => {
        this.hoverEnabled = false;
      },
    });

    this.header$ = this.store.select(RouterState.state).pipe(
      map((state: any) => state?.data?.header),
      distinctUntilChanged()
    );

    this.isShowNavbar$ = this.store.select(RouterState.state).pipe(
      map((state: any) => state?.data?.showNavBar ?? true),
      distinctUntilChanged()
    );

    this.hasFloatingButton$ = this.store.select(RouterState.state).pipe(
      map((state: any) => state?.data?.hasFloatingButton ?? true),
      distinctUntilChanged()
    );

    this.store
      .select(RouterState.state)
      .pipe(
        untilDestroyed(this),
        tap((routerState: any) => {
          if (routerState?.url) {
            const params: WebVitalsParams = {
              params: routerState.params,
              path: routerState.url,
              applicationId: environment.applicationId,
              envFqdn: environment.apiDomain,
            };
            const options: WebVitalsOptions = {
              dev: environment.platform === 'local',
              debug: environment.platform === 'local',
              browser: isPlatformBrowser(this.platformId),
            };
            sendWebVitals(params, options);
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (this.isEventOpen) {
      this.observeHeaders(document.body);
    }

    document.addEventListener('sticky-change', (e) => {
      const [header, stuck] = [(e as CustomEvent).detail.target, (e as CustomEvent).detail.stuck];
      header.setAttribute('stuck', stuck);
      this.isStuck$.next(stuck);
      this.store.dispatch(new SetStuck(stuck));
    });
  }

  checkIfEventIsOpen(eventDate: Date, now: Date): void {
    const endEvent = new Date(eventDate);
    endEvent.setDate(endEvent.getDate() + 1);
    this.isEventOpen = now.getTime() < endEvent.getTime();
    if (!this.isEventOpen) {
      this.router.navigate(['/', FeaturesRoutingEnum.EventClosed]);
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  observeHeaders(container: Element): void {
    const observer = new IntersectionObserver(
      (records, _) => {
        for (const record of records) {
          const targetInfo = record.boundingClientRect;
          const stickyTarget = record.target.parentElement?.querySelector(`.${this.headerClass}`);
          const rootBoundsInfo = record.rootBounds;

          // Stay sticking if
          if (!!stickyTarget && !!this.stickyHeader$.value) {
            this.fireEvent(true, stickyTarget);
          } else {
            // Started sticking.
            if (
              !!stickyTarget &&
              rootBoundsInfo?.top !== undefined &&
              targetInfo.bottom < rootBoundsInfo.top &&
              !this.noStickyHeader$.value
            ) {
              this.fireEvent(true, stickyTarget);
            }

            // Stopped sticking.
            if (
              (!!stickyTarget &&
                rootBoundsInfo?.top !== undefined &&
                targetInfo.bottom >= rootBoundsInfo.top &&
                targetInfo.bottom < rootBoundsInfo.bottom &&
                !this.noStickyHeader$.value) ||
              // for pages like drive and money where header stay fixed
              // fire sticky event to false in order to automatically stick header
              (!!this.noStickyHeader$.value && !!stickyTarget)
            ) {
              this.fireEvent(false, stickyTarget);
            }
          }
        }
      }
      // options
      // root defaults to the browser viewport when undefined
      // threshold defaults to 0 when undefined
    );

    // Add the top sentinels to each section and attach an observer.
    const headerSentinel = this.addSentinel(container);
    if (headerSentinel) {
      observer.observe(headerSentinel as Element);
    }
  }

  addSentinel(container: Element): HTMLDivElement | undefined {
    const sentinel = document.createElement('div');
    sentinel.classList.add('sticky-header-sentinel');
    return container.querySelector(`.${this.headerClass}`)?.parentElement?.appendChild(sentinel);
  }

  fireEvent(stuck: boolean, target: Element): void {
    const e = new CustomEvent('sticky-change', { detail: { stuck, target } });
    document.dispatchEvent(e);
  }

  private triggerObserveHeaders(router: Router): void {
    router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
        map((e) => (e as NavigationEnd).url),
        distinctUntilChanged()
      )
      .subscribe({
        next: () => {
          if (!this.isEventOpen) {
            this.observeHeaders(document.body);
          }
        },
      });
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event: any): void {
    this.hoverEnabled = event.pointerType === 'mouse';
  }

  @HostListener('document:keydown.tab', ['$event'])
  onKeydownTabHandler(_: KeyboardEvent): void {
    this.accessibilityEnabled = true;
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDownHandler(_: MouseEvent): void {
    this.accessibilityEnabled = false;
  }
}
