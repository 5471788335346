import { environment } from '@environment';
import {
  TrackingConfig,
  createGoogleAnalyticsConfig,
  createHotjarConfig,
  createWizbiiAnalyticsConfig,
} from '@wizbii/tracking';

export const trackingConfig: TrackingConfig = {
  informationGroupKeys: [],
  uuidSetterDomain: `https://a.${environment.apiDomain}`,
  consentWidget: {
    version: environment.platform !== 'prod' ? 'dev' : '',
    apiDomain: environment.apiDomain,
    wizbiiApiDomain: environment.apiDomain,
    enableTermsModal: true,
    locale: 'fr',
    productId: environment.applicationId,
    productLabel: 'App Candidat',
    defaultValue: false,
    labels: {},
    legalUrlBanner: `https://${environment.apiDomain}/confidentialite`,
    rejectAll: true,
  },
  trackers: {
    analytics_wizbii: createWizbiiAnalyticsConfig(
      environment.analytics.waTrackingId,
      `https://a.${environment.apiDomain}`
    ),
    analytics_google: createGoogleAnalyticsConfig(environment.analytics.gaTrackingId),
    analytics_hotjar: createHotjarConfig({
      hjid: environment.analytics.hotjar.id,
      hjsv: environment.analytics.hotjar.version,
    }),
  },
  canDisableWizbiiAnalytiks: true,
};
