import { Injectable, inject } from '@angular/core';
import { NgxsOnInit, Selector, State, StateContext, StateToken, createSelector } from '@ngxs/store';
import { NetworkService } from '@shared/services/network/network.service';
import { OnlineStatus } from '@shared/services/network/online-status.enum';
import { Contract } from '@wizbii/models';
import { ContractWebservice } from '@wizbii/webservices';
import { filter, switchMap } from 'rxjs/operators';

export type ContractsStateModel = Contract[];
export const ContractsStateToken = new StateToken<ContractsStateModel>('contracts');

@State({
  name: ContractsStateToken,
  defaults: null,
})
@Injectable()
export class ContractsState implements NgxsOnInit {
  readonly #contractWebservice = inject(ContractWebservice);
  readonly #network = inject(NetworkService);

  @Selector([ContractsStateToken])
  static all(state?: ContractsStateModel): Contract[] | undefined {
    return state;
  }

  static contract(id: string): (contracts: Contract[]) => Contract | undefined {
    return createSelector([ContractsState.all], (contracts: Contract[] | undefined): Contract | undefined =>
      contracts && Array.isArray(contracts) ? contracts.find((contract) => contract.id === id) : undefined
    );
  }

  static contracts(ids: string[]): (contracts: Contract[]) => Contract[] | undefined {
    return createSelector([ContractsState.all], (contracts: Contract[] | undefined): Contract[] | undefined =>
      contracts && Array.isArray(contracts) ? contracts.filter((contract) => ids.includes(contract.id)) : undefined
    );
  }

  ngxsOnInit(ctx?: StateContext<ContractsStateModel>): void {
    this.#network.status
      .pipe(
        filter((status) => status === OnlineStatus.ONLINE),
        switchMap(() => this.#contractWebservice.get('fr_FR'))
      )
      .subscribe({
        next: (contracts) => {
          ctx?.setState(contracts);
        },
      });
  }
}
