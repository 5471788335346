import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
  MatBottomSheetModule,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { SharedModule } from '@shared/shared.module';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, MatBottomSheetModule],
})
export class BottomSheetComponent {
  @ViewChild(TemplateRef) template?: TemplateRef<any>;

  @Input() ariaLabelSheet?: string;

  @Output() quitFn = new EventEmitter<void>();

  private matBottomSheetRef?: MatBottomSheetRef;
  private managers: any[] = [];

  destroyed$: Subject<void> = new Subject<void>();

  moving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sheetP$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  sheetH$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  contentScroll$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  bottomSheetElement$: BehaviorSubject<HTMLElement | undefined> = new BehaviorSubject<HTMLElement | undefined>(
    undefined
  );

  private get Hammer() {
    return typeof window !== 'undefined' ? (window as any).Hammer : undefined;
  }

  readonly #bottomSheet = inject(MatBottomSheet);
  readonly #renderer = inject(Renderer2);
  readonly #ngZone = inject(NgZone);

  open(config?: MatBottomSheetConfig): void {
    if (!this.template) {
      return;
    }

    this.matBottomSheetRef = this.#bottomSheet.open(this.template, {
      ...config,
      ariaLabel: this.ariaLabelSheet,
    });

    combineLatest([
      this.sheetP$.pipe(distinctUntilChanged()),
      this.bottomSheetElement$.pipe(filter((element): element is HTMLElement => !!element)),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([sheetP, elementSheet]) => {
          this.#renderer.setStyle(
            elementSheet,
            'transform',
            `translateY(${-((sheetP * 100) / elementSheet.clientHeight)}%)`
          );
        },
      });

    this.matBottomSheetRef
      .afterOpened()
      .pipe(take(1))
      .subscribe({
        next: () => {
          const bottomSheetElement: HTMLElement = document
            .getElementsByClassName('wiz-bottom-sheet')
            .item(0) as HTMLElement;
          const overlayContainerElement: HTMLElement = document
            .getElementsByClassName('cdk-overlay-container')
            .item(0) as HTMLElement;

          this.bottomSheetElement$.next(bottomSheetElement);
          this.sheetH$.next(bottomSheetElement.clientHeight);

          this.managers.push(
            this.bindHammer(bottomSheetElement, bottomSheetElement),
            this.bindHammer(overlayContainerElement, bottomSheetElement)
          );
        },
      });

    this.matBottomSheetRef
      .afterDismissed()
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.sheetP$.next(0);
          this.bottomSheetElement$.next(undefined);
          this.managers.forEach((m) => m.destroy());
          this.destroyed$.next();
          this.quitFn.emit();
        },
      });
  }

  protected bindHammer(element: HTMLElement, elementSheet: HTMLElement): any {
    return this.#ngZone.run((_) => {
      const manager = new this.Hammer(element, {
        recognizers: [[this.Hammer.Pan, { direction: this.Hammer.DIRECTION_VERTICAL }]],
      });

      manager.on('panstart panup pandown panend', (ev: any) => {
        this.move(elementSheet, ev);
      });

      return manager;
    });
  }

  move(elementSheet: HTMLElement, event: any): void {
    const delta = -event.deltaY;

    if (event.type === 'panup' || event.type === 'pandown') {
      this.moving$.next(true);

      if (event.deltaY > 0) {
        this.sheetP$.next(delta);
      }
    }

    if (event.isFinal) {
      this.contentScroll$.next(elementSheet.scrollTop);
      this.moving$.next(false);

      if (this.sheetP$.value < -30) {
        this.sheetP$.next(-this.sheetH$.value);
        this.close(elementSheet);
      } else {
        this.sheetP$.next(0);
      }
    }
  }

  close(elementSheet?: HTMLElement): void {
    if (!this.matBottomSheetRef) {
      return;
    }

    if (elementSheet) {
      this.#renderer.addClass(elementSheet, 'closed');
      setTimeout(() => {
        this.#renderer.addClass(elementSheet, 'hide');
        this.matBottomSheetRef?.dismiss();
      }, 400);

      return;
    }

    this.matBottomSheetRef.dismiss();
  }
}
