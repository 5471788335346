import { Injectable, inject } from '@angular/core';
import { NgxsOnInit, Selector, State, StateContext, StateToken, createSelector } from '@ngxs/store';
import { NetworkService } from '@shared/services/network/network.service';
import { OnlineStatus } from '@shared/services/network/online-status.enum';
import { Degree } from '@wizbii/models';
import { DegreeWebservice } from '@wizbii/webservices';
import { filter, switchMap } from 'rxjs/operators';

export type DegreesStateModel = Degree[];
export const DegreesStateToken = new StateToken<DegreesStateModel>('degrees');

@State({
  name: DegreesStateToken,
  defaults: null,
})
@Injectable()
export class DegreesState implements NgxsOnInit {
  readonly #degreeWebservice = inject(DegreeWebservice);
  readonly #network = inject(NetworkService);

  @Selector([DegreesStateToken])
  static all(state?: DegreesStateModel): Degree[] | undefined {
    return state;
  }

  static degrees(ids: string[]): (degrees: Degree[]) => Degree[] | undefined {
    return createSelector([DegreesState.all], (degrees: Degree[] | undefined): Degree[] | undefined =>
      degrees && Array.isArray(degrees) ? degrees.filter((degree) => ids.includes(degree.id)) : undefined
    );
  }

  ngxsOnInit(ctx?: StateContext<DegreesStateModel>): void {
    this.#network.status
      .pipe(
        filter((status) => status === OnlineStatus.ONLINE),
        switchMap(() => this.#degreeWebservice.get('fr_FR'))
      )
      .subscribe({
        next: (degrees) => {
          ctx?.setState(degrees);
        },
      });
  }
}
