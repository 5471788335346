<div class="closed-event">
  <h2 class="closed-event__description">
    L&apos;événement {{ eventName }} - {{ eventDate | date : 'shortDate' }} est maintenant terminé.
  </h2>
  <p class="closed-event__thanks">Merci d&apos;avoir participé&nbsp;!</p>
  <!--<ul class="ul-clean closed-event__btn-list">
    <li>
      <button type="button" class="btn closed-event__btn" (click)="onClick()">
        Participer à l&apos;enquête de satisfaction
      </button>
    </li>
    <li>
      <button type="button" class="btn closed-event__btn closed-event__btn__contact" (click)="onClick()">
        Contacter l&apos;organisateur
      </button>
    </li>
  </ul>-->
</div>
