import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { EMPTY, MonoTypeOperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function catchNotFoundResolver<T>(router: Router, location: Location, url: string): MonoTypeOperatorFunction<T> {
  return (input$) =>
    input$.pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          router
            .navigate(['/', FeaturesRoutingEnum.NotFound], { skipLocationChange: true })
            .then((_) => location.go(url));
          return EMPTY;
        }
        return throwError(() => err);
      })
    );
}
