import { environment } from '@environment';

export const bugsnagConfig = {
  apiKey: environment.bugsnag.key,
  appVersion: environment.version,
  autoTrackSessions: false,
  releaseStage: environment.platform,
  isDeployed: environment.isDeployed,
  groupingRules: [],
};
