import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetSelectedCompanyName } from '@stores/selected-interview/selected-interview.actions';

export interface SelectedInterviewStateModel {
  company: { name: string };
}
export const SelectedInterviewStateToken = new StateToken<SelectedInterviewStateModel>('selectedInterview');

@State({
  name: SelectedInterviewStateToken,
  defaults: undefined,
})
@Injectable()
export class SelectedInterviewState {
  @Selector([SelectedInterviewStateToken])
  static selectedInterview(state?: SelectedInterviewStateModel): SelectedInterviewStateModel | undefined {
    return state;
  }

  @Selector([SelectedInterviewStateToken])
  static selectedCompanyName(state?: SelectedInterviewStateModel): string | undefined {
    return state?.company.name;
  }
  @Action(SetSelectedCompanyName)
  setSelectedCompanyName(
    ctx: StateContext<SelectedInterviewStateModel>,
    { companyName }: SetSelectedCompanyName
  ): void {
    ctx.patchState({
      company: {
        name: companyName,
      },
    });
  }
}
