import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { indexedDBStore } from '@core/indexed-db.interface';
import { NetworkService } from '@shared/services/network/network.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
  private readonly network = inject(NetworkService);
  private readonly dbService = inject(NgxIndexedDBService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.network.isOnline()) {
      return next.handle(request);
    }

    if (request.method === 'GET') {
      return next.handle(request);
    }

    return this.dbService.add<HttpRequest<any>>(indexedDBStore, request).pipe(switchMap(() => next.handle(request)));
  }
}
