export class SetToken {
  static readonly type = '[Auth] Set token';
  constructor(public token: string) {}
}

export class SetSseToken {
  static readonly type = 'SetSseToken';
  constructor(public token: string) {}
}

export class SetTokenExpired {
  static readonly type = 'SetTokenExpired';
  constructor(public expired: boolean) {}
}
