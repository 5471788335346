import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { AuthService } from '@webservices/auth/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);

  isAuth(token?: string | null): boolean | UrlTree {
    const tokenCookie = this.authService.getTokenFromCookies();

    if (!token && !tokenCookie) {
      return false;
    }

    if (!tokenCookie || (token && tokenCookie !== token)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.authService.setTokenCookie(token!);
    }

    this.authService.setTokenInStore(token ?? tokenCookie);

    if (token) {
      return this.router.parseUrl(`/${FeaturesRoutingEnum.Jobs}`);
    }

    return true;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    const tokenQueryParam = route.queryParamMap.get('token');
    const tokenParam = route.paramMap.get('token');
    return this.isAuth(tokenParam ?? tokenQueryParam);
  }
}
