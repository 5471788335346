import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { OnlineStatus } from '@shared/services/network/online-status.enum';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkService implements OnDestroy {
  static EVENT_TYPE_ONLINE = 'online';
  static EVENT_TYPE_OFFLINE = 'offline';
  private _status = new EventEmitter<OnlineStatus>(true);
  status: Observable<OnlineStatus>;

  constructor() {
    this.bind();

    this.status = this._status.pipe(startWith(OnlineStatus.ONLINE));

    window.addEventListener(NetworkService.EVENT_TYPE_ONLINE, this.onOnline);
    window.addEventListener(NetworkService.EVENT_TYPE_OFFLINE, this.onOffline);
  }

  ngOnDestroy(): void {
    window.removeEventListener(NetworkService.EVENT_TYPE_ONLINE, this.onOnline);
    window.removeEventListener(NetworkService.EVENT_TYPE_OFFLINE, this.onOffline);
  }

  public isOnline(): boolean {
    return navigator.onLine;
  }

  private bind() {
    this.onOnline = this.onOnline.bind(this);
    this.onOffline = this.onOffline.bind(this);
  }

  private onOnline() {
    this._status.emit(OnlineStatus.ONLINE);
  }

  private onOffline() {
    this._status.emit(OnlineStatus.OFFLINE);
  }
}
