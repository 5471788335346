import type { CandidateInformation } from '@models/candidate/candidate-information';
import { Participation } from '@models/candidate/participation';
import type { OpsEvent } from '@models/event/ops-event';
import { CandidatePlanning } from '@models/planning/candidate-planning';
import { Selector } from '@ngxs/store';
import { CandidateState } from '@stores/candidate/candidate.state';
import { EventState } from '@stores/event/event.state';

export class EventCandidateSelectors {
  @Selector([EventState.event, CandidateState.candidate])
  static eventParticipation(event: OpsEvent, candidate?: CandidateInformation): Participation | undefined {
    return candidate?.participations.find((participation) => participation.eventId === event.id);
  }

  @Selector([EventCandidateSelectors.eventParticipation, CandidateState.planning])
  static hasMoreThanOneJob(
    eventParticipation: Participation | undefined,
    planning: CandidatePlanning | undefined
  ): boolean {
    return (
      ((eventParticipation?.candidatedJobsIds && eventParticipation.candidatedJobsIds.length > 1) ||
        (planning && planning.interviews.length > 1)) ??
      false
    );
  }
}
