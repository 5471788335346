import { Participation, toParticipation } from '@models/candidate/participation';

export interface CandidateInformation {
  firstName: string;
  id: string;
  lastName: string;
  mail: string;
  phone: string;
  participations: Participation[];
}

export function toCandidateInformation(data: any = {}): CandidateInformation {
  return {
    ...data,
    participations: data.participations.map((participation: any) => toParticipation(participation)),
  };
}
