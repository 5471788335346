<ng-template #template>
  <div class="close-container">
    <button
      class="btn-clean close-container__icon"
      [attr.aria-label]="'Fermer la modal ' + ariaLabelSheet"
      (click)="close()"
      type="button"
    >
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>
  <ng-content></ng-content>
</ng-template>
