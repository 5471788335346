import { convertDate } from '@commons/utils/date/date';

export interface OpsEvent {
  id: string;
  name: string;
  date: Date;
  scheduleEnd: Date;
  type: string;
  clientId: string;
  cvBookParameters: CvBookParameters;
  publicName?: string;
  dateSourcingEnd: Date;
  nbMaxPostulation: number;
}

export interface CvBookParameters {
  interviewDuration: number;
  interviewDebriefingDuration: number;
}

export function toOpsEvent(data: any = {}): OpsEvent {
  return {
    ...data,
    date: convertDate(data.date),
    scheduleEnd: convertDate(data.scheduleEnd),
    dateSourcingEnd: convertDate(data.dateSourcingEnd),
  };
}
