import { CandidateInformation } from '@models/candidate/candidate-information';
import { Participation } from '@models/candidate/participation';
import { CandidatePlanning } from '@models/planning/candidate-planning';

export class SetCandidate {
  static readonly type = '[Candidate] Set Candidate';
  constructor(public candidate: CandidateInformation) {}
}

export class UpdateCandidate {
  static readonly type = '[Candidate] Update Candidate';
  constructor(public candidate: CandidateInformation) {}
}

export class UpdateParticipation {
  static readonly type = '[Candidate] Update Participation';
  constructor(public participation: Participation) {}
}

export class SetCandidateJobsIds {
  static readonly type = '[Candidate] Set Candidate Jobs Ids ';
  constructor(public jobsIds: string[], public eventId: string) {}
}

export class SetCandidatePlanning {
  static readonly type = '[Candidate] Set Planning';
  constructor(public candidatePlanning: CandidatePlanning | undefined) {}
}
